<template>
  <div class="register_type_select">
    <a
      v-for="(item, index) in typeList"
      :key="index"
      :href="item.link"
      :style="{backgroundColor: item.bgColor}"
      class="type_link"
      target="_blank">
      <div :style="{background: `url(${item.image})`}" class="register_image">

      </div>

      <div class="type_content">
        <img :alt="item.title" :src="item.icon">
        <div class="item-title">{{ item.title }}</div>
        <div class="item-desc">{{ item.description }}</div>
      </div>

      <div :style="{color: item.btnTextColor}" class="person_content">立即注册</div>
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

/**
 * @author  XuHongli
 * @date  2023/1/30 14:32
 * @version 1.0
 * @description 注册类型选择
 */
export default {
  name: 'RegisterTypeSelect',
  data() {
    return {
      typeList: [
        {
          title: `${this.$tp().OPEN_SYSTEM_NAME}采购商账号注册`,
          description: '针对有采购诉求的客户指定的注册账号类型',
          icon: 'https://file.jingpingo.com/supply/material/4468019f-8985-42fe-bbda-38d16e806cbd.png',
          image: 'https://file.jingpingo.com/supply/material/e3af9080-5989-4724-a685-c1268a7152d3.jpg',
          bgColor: '#fff',
          link: '/registerSteps',
          btnTextColor: '#666'
        },
        {
          title: `${this.$tp().OPEN_SYSTEM_NAME}供应商账号注册`,
          description: '供应商入驻指定注册账号类型',
          icon: 'https://file.jingpingo.com/supply/material/19baa893-6121-4a95-a338-6ff2288dec42.png',
          image: 'https://file.jingpingo.com/supply/material/373662fd-05bd-4c7f-aefe-67789dd8c8fb.jpg',
          bgColor: '#ff6000',
          link: '',
          btnTextColor: '#fff'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['webKeyword'])
  },
  created() {
    this.typeList[1].link = this.$tp().OPEN_SSM_RESCTER ? `${this.$tp().OPEN_SSM_RESCTER}?type=Manufacturer` : `http://${this.$tp().OPEN_DOMAIN}/registerSteps?type=Manufacturer`
  }
}
</script>

<style lang="scss" scoped>
.register_type_select{
  background-color: #E5E5E5;
  display: flex;
  padding: 80px 0;
  justify-content: center;
}
// 整个盒子
.type_link{
  width: 560px;
  height: 380px;
  margin-left: 40px;
  margin-right: 40px;
  position: relative;
  // 图片
  .register_image{
    height: 320px;
    background-size: cover !important;
    position: relative;
    &::after{
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: #000;
      opacity: .4;
    }
  }
}

// 内容
.type_content{
  position: absolute;
  text-align: center;
  width: 100%;
  top: 96px;
  .item-title{
    color: #fff;
    margin-top: 35px;
    font-size: 18px;
    font-weight: bold;
  }
  .item-desc{
    color: #fff;
    margin-top: 16px;
  }
}

// 立即注册
.person_content{
  width: 100%;
  height: 60px;
  font-size: 16px;
  text-align: center;
  line-height: 60px;
}
</style>
